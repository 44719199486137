.App {
  font-family: "montserrat", serif;
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
ul.NoBullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: right;
  font-size: 15pt;
}

ul {
  text-align: left;
}
.App-header {
  background-color: #323643;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #93deff;
}
.AJBUDAbout {
  text-align: left;
  background-color: #202125;
  display: inline-block;
  color: #f7f7f7;
  margin: 0;

  width: 100%;
}
.AboutText {
  text-align: justify;
  font-size: 15pt;
}
.AboutTextRight {
  text-align: right;
  font-weight: 600;
  font-size: 12pt;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.AJBUDAbout_header {
  font-size: 30pt;
  font-weight: 600;
  text-align: left;
}
.H2About {
  font-weight: 600;
  text-align: left;
}
/*img {
  max-width: 650px;
  max-height: 488px;
}

color palette:
#323643 rgb(50, 54, 067)
#606470 rgb(96, 100, 112)
#93DEFF rgb(147, 222, 255)
#F7F7F7 rgb(247, 247, 247)
*/
